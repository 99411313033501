exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-find-us-index-js": () => import("./../../../src/pages/find-us/index.js" /* webpackChunkName: "component---src-pages-find-us-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-eyes-and-brows-js": () => import("./../../../src/pages/services/eyes-and-brows.js" /* webpackChunkName: "component---src-pages-services-eyes-and-brows-js" */),
  "component---src-pages-services-faux-freckles-tattoo-js": () => import("./../../../src/pages/services/faux-freckles-tattoo.js" /* webpackChunkName: "component---src-pages-services-faux-freckles-tattoo-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-lemon-bottle-js": () => import("./../../../src/pages/services/lemon-bottle.js" /* webpackChunkName: "component---src-pages-services-lemon-bottle-js" */),
  "component---src-pages-services-lumi-eyes-js": () => import("./../../../src/pages/services/lumi-eyes.js" /* webpackChunkName: "component---src-pages-services-lumi-eyes-js" */),
  "component---src-pages-services-microblading-js": () => import("./../../../src/pages/services/microblading.js" /* webpackChunkName: "component---src-pages-services-microblading-js" */),
  "component---src-pages-services-profhilo-js": () => import("./../../../src/pages/services/profhilo.js" /* webpackChunkName: "component---src-pages-services-profhilo-js" */)
}

