module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ashk Aesthetics","short_name":"Ashk Aesthetics","description":"Ashk Aesthetics Beauty Salon","lang":"en","display":"standalone","icon":"src/assets/icons/favicon.ico","start_url":"/","background_color":"#D7C2A4","theme_color":"#444444","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5a0514d689760ecfaabffd09632b697c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
